<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primário" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>
        
        <!-- Navegação Superior  -->
        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Início</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a :href="downloadURL">Baixar</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="blogs">Blogs</a>
            </li>
            <!-- Multi Línguas -->
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <div class="language-selector">
                <select id="language-select" v-model="selectedLanguage"
                  placeholder="Selecionar Idioma" @change="changeLanguage">
                  <option v-for="(data, i) in lang" :key="`lang_${i}`" :value="data.lang">
                    {{ data.val }}
                  </option>
                </select>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <article>
              <div class="inside-article">
                <div class="entry-content" itemprop="text">
                  <div class="wp-block-cover alignwide has-parallax" style="min-height: 200px">
                    <span aria-hidden="true"
                      class="wp-block-cover__background has-background-dim-80 has-background-dim wp-block-cover__gradient-background has-background-gradient"
                      style="background: linear-gradient(180deg, rgb(2, 247, 229) 0%, rgb(0, 0, 0) 99%)" />
                    <div class="wp-block-cover__inner-container">
                      <!-- Logo Principal -->
                      <div class="wp-block-image ic sdw">
                        <figure class="aligncenter size-full">
                          <img decoding="async" width="512" height="512" src="../assets/logo.webp"
                            alt="GB WhatsApp APK" class="wp-image-615">
                        </figure>
                      </div>

                      <h1 id="gbwhatsapp-pro" class="has-text-align-center hd">
                        GBWhatsApp
                      </h1>

                      <div class="code-block code-block-4"
                        style="margin: 8px auto; text-align: center; display: block; clear: both">
                        <p class="has-text-align-center v">
                          Versão: <strong>18.10</strong> | Tamanho: <strong>77MB</strong>
                        </p>
                      </div>

                      <p class="has-text-align-center d">
                        O aplicativo GBWhatsApp é uma versão modificada do WhatsApp oficial. A diferença
                         entre o GB WhatsApp e o WhatsApp está nas funcionalidades personalizadas. O aplicativo GB WhatsApp 
                         oferece mais funcionalidades personalizadas que atraem milhões de usuários. Essas funcionalidades atraentes 
                         incluem ocultar os dois tiques, salvar status, modo DND, mensagens não lidas, 
                         anti-revogar mensagens, temas e fontes ilimitadas, entre outros. 
                      </p>

                      <div
                        class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                        <div class="wp-block-button">
                          <a class="wp-block-button__link wp-element-button"
                            @click="downloadClick">Baixar GBWhatsApp Novo
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div id="beesads" />

                  <!-- Tabela de Conteúdos -->
                  <div id="toc_container" class="no_bullets">
                    <section class="table-content" v-show="!tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Tabela de Conteúdos
                        <img src="../assets/minus.svg" alt="minus">
                      </div>

                      <ul class="toc_list">
                        <li>
                          <a href="#TBC_0"><span class="toc_number toc_depth_1">1.</span> GB WhatsApp Pro V18.10 foi lançado!</a>
                        </li>
                        <li>
                          <a href="#TBC_1"><span class="toc_number toc_depth_1">2.</span> O que é GB WhatsApp e GBWhatsApp Pro?</a>
                        </li>
                        <li>
                          <a href="#TBC_2"><span class="toc_number toc_depth_2">3.</span> Por que as pessoas preferem usar GB WhatsApp？</a>
                        </li>
                        <li>
                          <a href="#TBC_3"><span class="toc_number toc_depth_3">4.</span> Qual é a diferença entre GB WhatsApp pro e WhatsApp?</a>
                        </li>
                        <li>
                          <a href="#TBC_4"><span class="toc_number toc_depth_4">5.</span> Recursos Quentes do GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_5"><span class="toc_number toc_depth_5">6.</span> Baixar o APK do GB WhatsApp Versão Mais Recente 18.10</a>
                        </li>
                        <li>
                          <a href="#TBC_6"><span class="toc_number toc_depth_6">7.</span>
                            Captura de Tela do GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_7"><span class="toc_number toc_depth_7">8.</span> Atualização do GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_8"><span class="toc_number toc_depth_8">9.</span> Alternativas ao GB WhatsApp</a>
                        </li>
                        <li>
                          <a href="#TBC_9"><span class="toc_number toc_depth_9">10.</span> O GBWhatsApp é seguro?</a>
                        </li>
                        <li>
                          <a href="#TBC_10"><span class="toc_number toc_depth_10">11.</span> FAQ sobre o GBWhatsApp Pro</a>
                        </li>
                        <li>
                          <a href="#TBC_11"><span class="toc_number toc_depth_11">12.</span>
                            Como espionar o WhatsApp dos outros sem tocar no celular deles?</a>
                        </li>
                        <li>
                          <a href="#TBC_12"><span class="toc_number toc_depth_12">13.</span> Conclusão</a>
                        </li>
                      </ul>
                      
                    </section>
                    <section class="table-content hide" v-show="tableHide">
                      <div class="table-content-title" @click="tableHide = !tableHide">
                        Tabela de Conteúdos
                        <img src="../assets/plus.svg" alt="plus">
                      </div>
                    </section>
                    
                  </div>

                  <h2 class="h">
                    <span id="TBC_0"> GB WhatsApp Pro V18.10 foi lançado!
                    </span>
                  </h2>
                  <h3>❗❗ GB WhatsApp 18.10 foi lançado ❗❗ </h3>
                  <p>
                    Os usuários podem atualizar esta nova versão agora! O que há de novo nesta 
                    versão? Como em atualizações anteriores, a versão mais recente do GB WhatsApp
                     também atualizou o sistema de proteção anti-bloqueio e adicionou uma introdução 
                     para guiar os usuários a corrigir problemas de bloqueio de contas. Os usuários podem atualizar sua versão antiga do GB WhatsApp agora. 
                  </p>

                  <h2 class="h">
                    <span id="TBC_1">O que é GB WhatsApp e GBWhatsApp Pro? </span>
                  </h2>

                  <p>
                    Você provavelmente já está familiarizado com o WhatsApp, mas já ouviu falar do 
                    GB WhatsApp? É uma versão aprimorada do WhatsApp padrão.
                     Também existe o WhatsApp Pro. Ambos os aplicativos são modificados. 
                     Eles fornecem recursos premium baseados no WhatsApp oficial. 
                     Enquanto alguns sites apresentam os dois aplicativos separadamente, a verdade é que
                      eles compartilham o mesmo APK com pequenas diferenças de recursos. Não há grandes
                       diferenças entre o APK do GBWhatsApp e o APK do GB WhatsApp Pro. Portanto, você pode 
                       escolher baixar um com base na sua preferência.
                  </p>

                  <h2 class="h">
                    <span id="TBC_2">Por que as pessoas preferem usar GB WhatsApp？ </span>
                  </h2>

                  <p>
                    Há pouca diferença entre o WhatsApp original, GB WhatsApp, 
                    GB WhatsApp Pro e <a class="jump-url" target="_blank" href="https://fmmodapk.com/">FM WhatsApp</a> ao enviar mensagens. 
                    No entanto, o WhatsApp é oficial e está disponível para várias plataformas. 
                    Os outros estão disponíveis apenas para sistemas Android. Então, por que as pessoas preferem usar 
                    o APK do GB WhatsApp Pro? Na verdade, o GB WhatsApp Pro tem mantido atualizações ao longo dos anos. E, ele adicionou recursos personalizados extras e mais opções de privacidade.
                  </p>

                  <div
                    class="is-layout-flex wp-container-5 wp-block-columns are-vertically-aligned-center">
                    <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                      <p class="fet">
                        <i class="fa fa-check" /> Tique Azul
                      </p>

                      <p class="fet">
                        <i class="fa fa-check-double" /> Tique Duplo
                      </p>

                      <p class="fet">
                        <i class="fa fa-dot-circle" /> Estar Online
                      </p>

                      <p class="fet">
                        <i class="fa fa-keyboard" /> Status de Digitação
                      </p>

                      <p class="fet">
                        <i class="fa fa-stop-circle" /> Status de Gravação
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column is-vertically-aligned-center">
                      <p class="fet">
                        <i class="fa fa-microphone" /> Configurações de Microfone
                      </p>

                      <p class="fet">
                        <i class="fa fa-history" /> Mensagens Agendadas
                      </p>

                      <p class="fet">
                        <i class="fa fa-reply-all" /> Resposta Automática
                      </p>

                      <p class="fet">
                        <i class="fa fa-cloud-download-alt" /> Salvar Status
                      </p>

                      <p class="fet">
                        <i class="fa fa-plane" /> Modo DND
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column">
                      <p class="fet">
                        <i class="fa fa-ban" /> Serviço Anti-Bloqueio
                      </p>

                      <p class="fet">
                        <i class="fa fa-fingerprint" /> Bloqueio por Impressão Digital
                      </p>

                      <p class="fet">
                        <i class="fa fa-cogs" /> Temas &amp; Fontes
                      </p>

                      <p class="fet">
                        <i class="fa fa-user-circle" /> Mudar Ícone
                      </p>

                      <p class="fet">
                        <i class="fa fa-phone-alt" /> Desativar Chamadas
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_3"> Qual é a diferença entre o GB WhatsApp Pro e o WhatsApp?
                    </span>
                  </h2>
                  <p>
                    Após a breve introdução ao GB WhatsApp, aqui está uma comparação clara 
                    entre o GB WhatsApp e o WhatsApp. Comparando essas diferenças de recursos, você pode decidir qual usar.
                  </p>

                  <figure class="wp-block-table">
                    <table>
                      <thead>
                        <tr>
                          <th>Recurso</th>
                          <th>GBWhatsApp Pro</th>
                          <th>WhatsApp</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>Modo DND</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Modo Fantasma</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Limite de Envio de Arquivos</td>
                          <td>999MB</td>
                          <td>100MB</td>
                        </tr>
                        <tr>
                          <td>Qualidade da Imagem</td>
                          <td>Original</td>
                          <td>Comprimida</td>
                        </tr>
                        <tr>
                          <td>Temas</td>
                          <td>4000+</td>
                          <td>3</td>
                        </tr>
                        <tr>
                          <td>Resposta Automática</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Mensagem Não Lida</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Mensagens em Massa</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Mudar Fonte</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Anti-Revoke</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Ocultar Tiques Duplos</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Mudar Tiques Azuis</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Bloqueio de Segurança</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                        <tr>
                          <td>Mudar Ícone</td>
                          <td>✅</td>
                          <td>❌</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <!-- Recursos -->
                  <h2 class="h">
                    <span id="TBC_4"> Recursos Quentes do GB WhatsApp </span>
                  </h2>

                  <div class="is-layout-flex wp-container-8 wp-block-columns">
                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Tradução</h3>
                      <p>
                        O GB WhatsApp Pro agora permite que os usuários traduzam mais mensagens dentro de um chat, tornando a comunicação mais acessível entre idiomas.
                      </p>

                      <h3 class="h3">Ocultar o Ícone do Perfil</h3>
                      <p>
                        Na versão mais recente do GB WhatsApp Pro, você pode ocultar seu ícone de perfil de outros. Este recurso agora está disponível para privacidade adicional.
                      </p>

                      <h3 class="h3">Ir para a Primeira Mensagem</h3>
                      <p>
                        Precisa revisar mensagens importantes desde o início de um chat? Com este recurso, você não precisa mais rolar manualmente. Basta clicar na opção e você pulará diretamente para a primeira mensagem em segundos.
                      </p>

                      <h3 class="h3">Temas</h3>
                      <p>
                        O GB WhatsApp Pro oferece uma ampla gama de temas, permitindo que você mude o layout do seu aplicativo sempre que quiser. Com mais de 4000+ temas disponíveis, esta é uma das principais razões pelas quais os usuários escolhem o GB WhatsApp.
                      </p>

                      <h3 class="h3">Modo DND</h3>
                      <p>
                        O modo "Não Perturbe" embutido no GB WhatsApp Pro permite que você desative a conexão com a internet apenas para o aplicativo, para que você não seja interrompido por mensagens do WhatsApp enquanto usa outros aplicativos no seu telefone.
                      </p>

                      <h3 class="h3">Bloqueio de Aplicativo</h3>
                      <p>
                        O GB WhatsApp Pro inclui uma função de bloqueio de aplicativo, permitindo que você proteja suas mensagens exigindo um padrão, PIN, senha ou impressão digital para acessar o aplicativo. Isso garante que suas conversas permaneçam privadas, mesmo que outras pessoas tenham acesso ao seu telefone.
                      </p>
                    </div>

                    <div class="is-layout-flow wp-block-column">
                      <h3 class="h3">Resposta Automática</h3>
                      <p>
                        Configure respostas automáticas para mensagens quando você não puder responder imediatamente. Você pode usar esse recurso para fins comerciais sem precisar baixar o APK do GB WhatsApp Business.
                      </p>

                      <h3 class="h3">Envio em Massa</h3>
                      <p>
                        Envie mensagens ilimitadas para múltiplos contatos ao mesmo tempo com o recurso de Envio em Massa, facilitando o compartilhamento de informações com todos os seus contatos em apenas alguns cliques.
                      </p>

                      <h3 class="h3">Ocultar Status Visto</h3>
                      <p>
                        Com um único clique, você pode visualizar e baixar os status de seus contatos sem que eles saibam que você os viu, proporcionando mais controle sobre sua privacidade.
                      </p>

                      <h3 class="h3">Anti-Revoke</h3>
                      <p>
                        O recurso anti-revoke no GB WhatsApp Pro garante que mesmo que alguém exclua uma mensagem após enviá-la, você ainda poderá vê-la por padrão.
                      </p>

                      <h3 class="h3">Enviar Máximo de Imagens</h3>
                      <p>
                        O GB WhatsApp Pro permite que você envie mais de 100 imagens de uma vez, superando o limite oficial do WhatsApp. Você pode compartilhar várias fotos facilmente, sem complicações.
                      </p>

                      <h3 class="h3">Enviar Imagens Originais</h3>
                      <p>
                        Com o GB WhatsApp Pro, você pode enviar imagens em sua qualidade original, garantindo que suas fotos permaneçam nítidas e claras após o envio.
                      </p>

                      <h3 class="h3">Baixar Status</h3>
                      <p>
                        Salve as imagens e vídeos dos status carregados pelos seus contatos diretamente no GB WhatsApp Pro. Não há necessidade de aplicativos de terceiros, e esse recurso está disponível gratuitamente.
                      </p>
                    </div>
                  </div>

                  <h2 class="h">
                    <span id="TBC_5"> Baixar o APK do GB WhatsApp Versão Mais Recente 18.10 </span>
                  </h2>
                  <div class="wp-block-image ic">
                    <figure class="aligncenter size-full">
                      <img decoding="async" width="512" height="512" src="../assets/logo.webp"
                        alt="GBWhatsApp Pro" class="wp-image-615">
                    </figure>
                  </div>
                  <p>
                    O GBWA Pro continua atualizando o arquivo APK para usuários do Android. Recentemente, ele atualizou uma nova versão
                     18.10 e adicionou alguns novos recursos, incluindo ocultar o ícone do perfil, mais idiomas de tradução e
                      ir para a primeira mensagem em um chat. Então, qual é a informação do aplicativo do GB WhatsApp? Veja a tabela abaixo: 
                  </p>

                  <figure class="wp-block-table is-style-regular">
                    <table class="has-fixed-layout">
                      <tbody>
                        <tr>
                          <td>Nome</td>
                          <td>GBWhatsApp Pro</td>
                        </tr>
                        <tr>
                          <td>Versão</td>
                          <td>
                            <div class="code-block code-block-3" style="margin: 8px 0; clear: both">
                              18.10
                            </div>
                          </td>
                        </tr>
                        <tr>
                          <td>Tamanho</td>
                          <td>77M</td>
                        </tr>
                        <tr>
                          <td>Desenvolvedor</td>
                          <td><a href="https://gbapksdownload.com/">AlexMODS</a></td>
                        </tr>
                        <tr>
                          <td>Atualizado</td>
                          <td>Há 2 dias</td>
                        </tr>
                      </tbody>
                    </table>
                  </figure>

                  <div
                    class="is-horizontal is-content-justification-center is-layout-flex wp-container-1 wp-block-buttons">
                    <div class="wp-block-button">
                      <a class="wp-block-button__link wp-element-button"
                        @click="gotodownload()">Baixar GBWhatsApp Pro</a>
                    </div>
                  </div>

                  <p>
                    <span style="font-weight: bold;">Guia</span>: Clique no botão para baixar o GB WhatsApp Pro. Seu telefone pode avisá-lo de que o arquivo APK
                     é de uma fonte de terceiros e pode apresentar riscos potenciais. Para prosseguir, você precisará 
                     confiar no APK. Vá para as configurações do seu telefone e ative a permissão para instalar de fontes desconhecidas.
                  </p>

                  <h2 class="h">
                    <span id="TBC_6"> Captura de Tela do GB WhatsApp</span>
                  </h2>

                  <div class="wp-block-image">
                    <figure class="aligncenter size-full">
                      <picture>
                        <source srcset="../assets/Screenshot.webp" type="image/webp">
                        <img decoding="async" loading="lazy" width="1000" height="563"
                          src="../assets/Screenshot.webp" alt="GB Screenshot" class="wp-image-48">
                      </picture>
                    </figure>
                  </div>

                  <h2 class="h">
                    <span id="TBC_7"> Atualização do GB WhatsApp </span>
                  </h2>

                  <p>
                    Se o GB WhatsApp no seu telefone ainda for uma versão antiga do GB WhatsApp 2023, 
                    você deve desinstalá-lo e instalar a nova versão. Existem duas maneiras de atualizar a nova versão.
                  </p>
                  <li>
                    Primeiro, visite o site oficial de download do APK do GBWhatsApp para obtê-lo. Você também pode baixá-lo na 
                    página inicial do nosso site. Siga o guia para instalar o aplicativo. 
                  </li>
                  <li>
                    Segundo, vá para configurações e verifique a versão atual do GB WhatsApp. Se a versão atual 
                    for antiga, você pode tocar no botão "Atualizar GB WhatsApp" e aguardar o download. 
                  </li>

                  <h2 class="h">
                    <span id="TBC_8"> Alternativas ao GB WhatsApp </span>
                  </h2>
                  <p>
                    Não existe apenas um aplicativo modificado do WhatsApp. Se o GB WhatsApp ou o GB WhatsApp Pro não puderem 
                    ser usados no seu Android, você pode tentar as alternativas abaixo:
                  </p>
                  <li>FM WhatsApp</li>
                  <li>WhatsApp Plus</li>
                  <li>OB WhatsApp</li>
                  <li>OG WhatsApp</li>
                  <li>YO WhatsApp</li>
                  <li>Aero WhatsApp</li>
                  <li>Gold WhatsApp</li>
                  <li>Blue WhatsApp</li>

                
                  <h2 class="h">
                    <span id="TBC_9"> O GBWhatsApp é seguro? </span>
                  </h2>

                  <p>
                    A segurança de um aplicativo modificado é a primeira preocupação da maioria das pessoas. <br>

                    Com base em anos promovendo o GB WhatsApp, não encontramos nenhum usuário que 
                    tenha sofrido perda de informações pessoais. Apenas um pequeno número de pessoas reclama 
                    sobre perda de dados de chat ou problemas de bloqueio. Portanto, podemos dizer que o GBWhatsApp é seguro. <br>

                    O GB WhatsApp se desenvolveu a ponto de haver muitos sites que fornecem serviços de download.
                     Para usar o GB WhatsApp com segurança, recomendamos que os usuários escolham um site confiável e sempre obtenham 
                     informações atualizadas dele. Nosso site pode ser sua primeira escolha, porque não há riscos no APK do GB WhatsApp que fornecemos. <br>

                    Se você ainda não confia no APK de terceiros, pode usar um novo número de telefone para se registrar e fazer login 
                     no aplicativo GB WhatsApp para aproveitar esses recursos ilimitados. 
                  </p>


                  <h2 class="h">
                    <span id="TBC_10"> FAQ sobre o GBWhatsApp Pro </span>
                  </h2>
                  <div class="schema-faq wp-block-yoast-faq-block">
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Minha conta foi banida, como desbloquear?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Você pode vincular o GB WhatsApp como um dispositivo acompanhante do WhatsApp. Como alternativa, pesquise métodos no YouTube. Existem muitas soluções úteis disponíveis.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Como posso recuperar minha conta banida do GB WhatsApp?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Baixe a APK do GB WhatsApp atualizada e tente novamente. A versão 18.10 adicionou um guia para fazer login com sucesso.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Posso usar o GB Pro e o WhatsApp Oficial no mesmo telefone?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Sim, você pode usar os dois no mesmo dispositivo, mas com contas diferentes.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Posso transferir dados do WhatsApp para o GBWhatsApp?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Sim, vá até o drive local do seu telefone Android e encontre os dados do WhatsApp. Copie os arquivos para a pasta do GB WhatsApp. Para mais detalhes, confira nosso blog.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Meu número será banido ao usar o GBWA?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Não, isso foi um bug antigo. O GBWhatsApp agora é 100% seguro, e os <a href="https://www.whatsapp.com/legal/updates/terms-of-service/?lang=en">Termos de Serviço</a> do WhatsApp se aplicam.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Posso esconder o GBWhatsApp do meu telefone?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Você pode mudar o ícone do GBWA, então ninguém poderá dizer que é o WhatsApp.
                      </p>
                    </div>
                    <div class="schema-faq-section">
                      <strong class="schema-faq-question faq-q-open">Podemos usar o GB WhatsApp para fins comerciais?</strong>
                      <p class="schema-faq-answer faq-q-open" style="display: block">
                        Sim, a função de resposta automática é ótima para uso comercial, embora a opção de Loja não esteja disponível.
                      </p>
                    </div>
                  </div>



                  <h2 class="h">
                    <span id="TBC_11"> Como espionar o WhatsApp de outras pessoas sem tocar no celular delas?
                    </span>
                  </h2>

                  <p>
                    Embora o GB WhatsApp seja uma versão modificada do WhatsApp, ele foi desenvolvido apenas para alguns recursos premium. 
                    Os usuários podem usá-lo para mais funcionalidades personalizadas, mas o recurso de espionagem não está disponível. 
                    Esta ausência de um recurso de espionagem ressalta a importância da privacidade e da segurança dentro do aplicativo. 
                    Os usuários podem se sentir confiantes de que suas conversas pessoais e dados permanecem protegidos, uma vez que o GB WhatsApp 
                    não facilita o acesso não autorizado à atividade do usuário.
                  </p>


            
                  <h2 class="h">
                    <span id="TBC_12"> Conclusão </span>
                  </h2>

                  <p>
                    Para aqueles que buscam uma experiência de WhatsApp mais personalizada e rica em recursos, o GBWhatsApp Pro é uma excelente escolha. 
                    Com sua ampla gama de funcionalidades, é fácil ver por que milhões de usuários em todo o mundo o preferem ao WhatsApp original. 
                    Para se manter informado sobre futuras atualizações, não se esqueça de salvar ou marcar esta página .
                  </p>


                  <div style="height: 20px" aria-hidden="true" class="wp-block-spacer" />
                </div>
              </div>
            </article>
          </main>
        </div>
      </div>
    </div>

    <div id="comment-box">
      <div class="title">
        Deixe um Comentário
      </div>
      <input v-model="comment.name" type="text" class="nameClass" placeholder="seu nome">
      <input v-model="comment.email" type="email" class="emailClass" placeholder="Email">
      <textarea v-model="comment.content" class="contentClass" />
      <button class="submitClass" @click="submitClick">
        Enviar
      </button>
      <div class="commentList-box">
        <div v-for="item in showCommentList" :key="item.time" class="commentClass">
          <img src="@/assets/logo.webp" alt="">
          <div class="container">
            <div class="commentBoxClass">
              <div>
                <span class="commentName">{{ item.name }}</span>
                <span class="commentTime">{{ item.time }}</span>
              </div>
              <span class="commentContent">{{ item.content }}</span>
            </div>
            <div class="triangle" />
          </div>
        </div>
      </div>
      <button class="moreClass" @click="moreClick">
        {{ showText }}
      </button>
    </div>

    <div id="snippet-box">
      <div class="snippet-title">
        Resumo
      </div>

      <div itemscope="" itemtype="https://schema.org/SoftwareApplication">
        <div class="aio-info">
          <div class="snippet-label-img snippet-clear">
            Avaliação do Autor
          </div>
          <div class="snippet-data-img">
            <span class="star-img"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png" alt="1star"><img
                src="@/assets/1star.png" alt="1star"><img src="@/assets/1star.png"
                alt="1star"></span>
          </div>
          <div class="snippet-clear" />
          <div class="aggregate_sec" itemprop="aggregateRating" itemscope=""
            itemtype="https://schema.org/AggregateRating">
            <div class="snippet-label-img">
              Avaliação Agregada
            </div>
            <div class="snippet-data-img">
              <span itemprop="ratingValue">3.65</span> com base em
              <span class="rating-count" itemprop="ratingCount">46258</span>
              votos
            </div>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Nome do Software
          </div>
          <div class="snippet-data-img">
            <span itemprop="name">GBWhatsApp APK</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Sistema Operacional
          </div>
          <div class="snippet-data-img">
            <span itemprop="operatingSystem">Android</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Categoria do Software
          </div>
          <div class="snippet-data-img">
            <span itemprop="applicationCategory">Aplicativo</span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Preço
          </div>
          <div class="snippet-data-img">
            <span itemprop="offers" itemscope="" itemtype="https://schema.org/Offer">
              <span itemprop="priceCurrency">$</span> <span itemprop="price"> 0</span></span>
          </div>
          <div class="snippet-clear" />
          <div class="snippet-label-img">
            Página de Destino
          </div>
          <div class="snippet-data-img">
            <a itemprop="featureList" href="https://gbwhatapks.com/">https://gbwhatapks.com/</a>
          </div>
          <div class="snippet-clear" />
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="#">Política de Privacidade</a>
          </div>
          <div class="copyright-bar">
            2022 © Todos os Direitos Reservados <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Baixar GBWhatsApp Novo
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
// import utlisMethods from '@/utlis/global.js';
import { allCommentList } from '@/utlis/allCommentList.js';
export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      tableHide: true,
      comment: {
        name: '',
        email: '',
        content: '',
      },
      apk: null,
      clickNum: 0,
      selectedLanguage: 'pt',
      lang: [
        { lang: 'en', val: '🇺🇸 English' },
        { lang: 'ar', val: '🇦🇪 اَلْعَرَبِيَّةُ' },
        { lang: 'id', val: '🇮🇩 Bahasa Indonesia' },
        { lang: 'pt', val: '🇵🇹 Português' },
        { lang: 'es', val: '🇪🇸 Español' },
        { lang: 'hi', val: '🇮🇳 हिंदी' },
      ],
    };
  },
  computed: {
    allCommentList () {
      const regularTime = Date.parse('2023/11/24 00:00:00');
      let currentTime = new Date().getTime();
      let Difference = Math.floor((currentTime - regularTime) / (3 * 24 * 60 * 60 * 1000));
      return allCommentList.slice(0, 20 + Difference).sort((a, b) => new Date(b.time).getTime() - new Date(a.time).getTime()) || [];
    },
    downloadURL () {
      // var params = window.location.search;
      // return `/downloadpage${params}`;
      if (typeof window === 'undefined') {
        // Server-side rendering logic
        return '/downloadpage'; // Adjust this based on your server-side logic
      } else {
        // Client-side rendering logic
        let params = window.location.search;
        return `/downloadpage${params}`;
      }
    },
    showText () {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        return 'Click Here To Load More Comments';
      } else {
        return 'No More Comments';
      }
    },
    showCommentList () {
      return this.allCommentList.slice(0, (this.clickNum + 1) * 5) || [];
    },
  },
  mounted () {
    this.getOfficalApk();

    // window.adsTag.renderInterstitial(54982);
  },
  methods: {
    moreClick () {
      if (this.clickNum < Math.ceil(this.allCommentList.length / 5) - 1) {
        this.clickNum += 1;
      }
    },
    submitClick () {
      this.$Dialog
        .confirm({
          title: 'Tip',
          message: 'Your comment has been submitted successfully and will be displayed below after review.',
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
        })
        .then(() => {
          this.comment.name = '';
          this.comment.email = '';
          this.comment.content = '';
        })
        .catch(() => {
          console.log('取消');
        });
    },
    changeLanguage (lang) {
      console.log(lang);
      // 处理语言选择变化
      // 这里可以添加其他处理逻辑，例如将语言保存到cookie或localStorage中
      this.$router.push({ name: `${this.selectedLanguage}-gb` });
    },
    downloadClick () {
      this.$router.push({ name: 'download' });
    },
    getOfficalApk (type = 1) {
      this.$server
        .getOfficalApk({
          domain: 'gbwhatsapp.chat',
          appName: 'GB_gbwhatsapp.chat',
        })
        .then((res) => {
          console.log(res);
          if (res.code == 200) {
            if (type == 1) {
              this.apk = res.data.apk;
            }
            this.lastupdate = res.data.promotion.updateTime;
            this.votes = res.data.promotion.totalCount;
            this.rating = res.data.promotion.score;
            console.log('apk getted');
          } else {
            this.apk = process.env.VUE_APP_GBLINK;
          }
        });
    },
    gotodownload () {
      // // const params = new URLSearchParams(window.location.href.search)
      // var params = "";
      // if (typeof window !== "undefined") {
      // 	params = window.location.search;
      // }
      // // window.location.href = "/downloadpage" + params;
      // return `/downloadpage${params}`;
      // utlisMethods.download(
      // 	"https://file.cmpc.fun/upapp/1699966982678/GBWhatsApp_2.23.20.632_gw_11_14_2101_sec_sign.apk"
      // );
      window.location.href = `https://gbapp.pro?apps=gb&domain=gbwhatsapp.chat&appName=GB_gbwhatsapp.chat`;

      // this.$global.download(this.apk);
    },
    gotoblog () {
      window.location.href = '/blogs';
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
